.review-section {
  font-family: 'Lucida Grande', Helvetica, Arial, sans-serif !important;
  font-size: 62.5%;
}

.list-group-item {
  padding: 20px !important;
}

.review-avatar {
  width: 50px;
  height: 50px;
}

.review-name {
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: bold;
}

.review-time {
  margin-left: 1rem;
  font-size: 1rem;
  color: gray;
}

.review-source {
  float: right;
}

a:hover {
  text-decoration: none;
}

.review-sourceLink {
  color: gray;
  font-size: 1rem;
}

.seeMore {
  color: gray;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  display: block;
}

.review-text {
  margin-top: 20px;
  font-size: 1rem;
}

.review-comment {
  margin: 20px 0 20px 0;
  font-size: 1rem;
}

/** Print */
@media print {
  .rating {
    -webkit-print-color-adjust: exact;
  }
  .rating i {
    text-shadow: none;
  }
}
