.dropdownComponent {
  .column {
    display: flex;
    .drop {
      display: flex;
      align-items: center;
      justify-content: center;
      > span {
        background-color: #fff;
        height: 40px;
        -ms-flex-align: self;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 20px;
        border: 0.5px solid #a1b4fa;
        color: #3d61d7;
        font-size: 16px;
        padding: 0 12px 0 12px;
      }
      .title {
        padding-right: 4px;
      }
    }
    .menu {
      padding: 4px 8px;
      color: #3d61d7;
      font-size: 18px;
      border-bottom: 1px solid #3d61d7;
    }
    .menu:last-child {
      border-bottom: 0px solid #3d61d7;
    }
    .dropdown-menu {
      border: 1px solid #3d61d7;
      border-radius: 8px;
    }
  }
}
