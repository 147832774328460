.star {
  color: orange;
}

.review-rating {
  margin: 10px 0;
}

.review-rating i {
  display: inline-block;
  font-size: 1.5rem;
  width: 0;
  height: 1.5em;
  border-width: 0 0.5em;
  border-style: solid;
  border-color: #fff;
  border-radius: 0.22em;
  /* color: white; */
  background: #eee;
  font-style: normal;
  line-height: 1.6em;
  text-indent: -0.5em;
}
