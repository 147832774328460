.appointment-tag {
  border: 1px solid #f0454e;
  color: #f0454e;
  font-size: 16px;
  /* min-width: 80px; */
  border-radius: 16px;
  margin: 0 4px;
  padding: 2px 10px;
  display: inline-block;
  text-align: center;
  word-break: keep-all;
}

.appointment-icon {
  height: 20px;
}

.appointment-action {
  margin-bottom: 8px;
}

.appointment-custom-tag {
  border: 1px solid #3d61d7;
  color: #3d61d7;
}

.appointment-td {
  vertical-align: 0% !important;
}

.appointment-modal-subtitle {
  font-size: 18px;
  margin: 8px 0px;
}

.appointment-modal-input {
  border: 1px solid #d0d0d0;
  border-radius: 8px;
  width: 100%;
  padding: 8px;
}

.appointment-textarea {
  margin: 0px 0px 0px 0px;
  border: 1px solid #d0d0d0;
  border-radius: 8px;
  width: 100%;
  padding: 8px;
}
