.appointment-tr {
  td {
    min-width: 100px;
    max-width: 150px;
  }
}
.appointment-tr[disabled] {
  pointer-events: none;
  opacity: 0.7;
  background-color: #ddd;
}

.appointment-modal {
  .note {
    .value {
      font-size: 18px;
    }
  }
}

.messaging-modal {
  .note {
    .title {
      font-size: 14px;
    }
    .value {
      font-size: 14px;
    }
  }
}

.appointment-page {
  .group-message-button {
    margin-left: 8px;
  }
}
