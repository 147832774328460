.react-tag-complete {
  position: relative;
  padding: 6px 0 0 6px;
  border-top: 1px solid #ccd4e6;
  border-bottom: 1px solid #ccd4e6;
  font-size: 11px;
  line-height: 1.2;
  height: 100%;
  cursor: text;

  &.is-focused {
  }

  .react-tags__selected {
    display: inline;
  }

  .react-tags__label {
    display: inline-block;
    padding: 6px 8px;
    font-size: inherit;
    line-height: inherit;
  }

  .react-tags__selected-tag {
    display: inline-flex;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    padding: 0 4px;
    border-radius: 16.5px;
    background-color: #3d61d7;
    height: 24px;
    align-items: center;
  }

  .react-tags__selected-tag {
    div {
      display: flex;
      padding: 0 4px;
      font-size: 13px;
      line-height: 1.43;
      letter-spacing: 0.07px;
      color: #fff;
    }
    i {
      font-size: 18px;
      border: none;
      color: #ccd4e6;
      &:hover {
        cursor: pointer;
      }
    }

    &.selected {
      border: 1px solid blue;
    }
  }

  .react-tags__selected-tag:hover,
  .react-tags__selected-tag:focus {
    //border-color: #B1B1B1;
  }

  .react-tags__search {
    display: inline-block;
    padding: 7px 2px;
    margin-bottom: 6px;
    max-width: 100%;
  }

  @media screen and (min-width: 30em) {
    .react-tags__search {
      position: relative;
    }
  }

  .react-tags__search input {
    max-width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    font-size: inherit;
    line-height: inherit;
  }

  .react-tags__search input::-ms-clear {
    display: none;
  }

  .react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 100000;
  }

  @media screen and (min-width: 30em) {
    .react-tags__suggestions {
      width: 240px;
    }
  }

  .react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  .react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 10px;
  }

  .react-tags__suggestions li mark {
    background: none;
    font-weight: 600;
  }

  .react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
  }

  .react-tags__suggestions li.is-active {
    background: rgba(80, 113, 255, 0.12);
  }

  .react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
  }
}
