.line-message-list-container {
  width: 60%;
  .card-container {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #a4b7c1;
    .m-card {
    }
    .status-label {
      margin: 0 8px 0 16px;
      display: flex;
      align-self: center;
      background-color: #3d61d7;
      border: 1px solid #ddd;
      border-radius: 16px;
      .text {
        padding: 4px 8px;
        color: #fff;
        font-size: 16px;
      }
    }
    .info {
      flex: 1;
      padding: 8px 8px;
      .title {
        font-size: 26px;
      }
      .type {
        font-size: 20px;
      }
      .time {
        font-size: 14px;
      }
    }
    .delivery-info {
      width: 20%;
      padding: 8px 8px;
      align-self: center;
      justify-content: center;
      .number {
        font-size: 1.5em;
      }
      .label {
      }
    }
  }

  .title-container {
    display: flex;
    flex-direction: row;
    .title {
      flex: 1;
      margin: 16px;
      font-size: 30px;
    }
    .create-message-button {
      align-self: center;
      background-color: #3d61d7;
      color: #fff;
      font-size: 18px;
      border-radius: 30px;
      padding: 15px 20px;
    }
  }
}

.line-create-message-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .message-container {
    flex: 1.5;
    margin-bottom: 30px;
  }
  .user-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    .cell {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .rt-tr-group {
      flex: 1;
    }
  }
}
