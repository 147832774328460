.clock-tabs {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.line-drop {
  /* background-color: aquamarine; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.line-drop > span {
  background-color: #fff;
  width: 150px;
  height: 40px;
  -ms-flex-align: self;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  border: 0.5px solid #a1b4fa;
  color: #3d61d7;
  font-size: 16px;
  padding: 0 12px 0 12px;
}

.line-down-icon {
  width: 16px;
  height: 8px;
}

.line-add-button {
  width: 40px;
  height: 40px;
  border: 0px;
  outline: none;
  background-color: white;
}

.line-add-button-image {
  width: 20px;
  height: 20px;
}

.line-age-column {
  display: flex;
}

.line-age-column > input {
  border: 0.5px solid #a1b4fa;
  border-radius: 20px;
  color: #3d61d7;
  font-size: 16px;
  padding: 0 12px 0 12px;
  width: 100px;
}

.line-tags-column {
  display: flex;
  margin: 0px 0px 8px 16px;
  align-items: center;
}

.line-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.line-timing {
  display: flex;
  align-items: center;
}

.line-card-containers {
  display: flex;
  justify-content: stretch;
}

.line-card-container {
  display: flex;
  padding: 8px;
  width: 172px;
}

.line-card {
  height: 250px;
  margin-left: 4px;
  border-radius: 8px;
  width: 100%;
}

.line-card-add {
  width: 140px;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin: 8px 8px 8px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.line-whole {
  display: flex;
}
.line-space {
  flex: 2;
}

textarea:focus,
input:focus,
button:focus {
  outline: none;
}

.line-create-title {
  margin: 16px;
  font-size: 30px;
}

.line-message-title-container {
  margin: 0px 0px 8px 16px;
  /* 上右下左 */
}

.line-message-title {
  font-size: 18px;
  margin: 0px 0px 8px 0px;
}

.line-message-title-input-container {
}

.line-message-title-input {
  width: 100%;
  border-radius: 24px;
  height: 40px;
  border: 1px solid #d0d0d0;
  font-size: 20px;
  padding-left: 12px;
}

.line-mobile-space {
  flex: 1;
  background-image: url('./GroupMessage/broadcast_mobile.png');
  background-size: 100% 100%;
  height: 480px;
  min-width: 275px;
}

.line-broadcast-mobile {
  width: 100%;
}

.line-mobile-screen {
  border: 1px solid #ddd;
  margin: 22% 16%;
  height: 70%;
  border-radius: 4px;
  background-color: hsla(0, 0%, 94%, 0.2);
}

.line-mobile-nav {
  height: 30px;
  width: 100%;
  background-color: #314f8f;
  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
}

.line-mobile-dialog-container {
  display: flex;
}

.line-mobile-nav-text {
  font-weight: 500;
}

.line-mobile-dialog {
  background-color: #efefef;
  margin: 8px 4px 0px 0px;
  padding: 4px;
  border-radius: 4px;
  word-break: break-word;
}

.line-mobile-icon {
  background-image: url('./GroupMessage/rabbitIcon.png');
  background-size: 100% 100%;
  width: 28px;
  height: 28px;
  margin: 6px;
  min-width: 28px;
}

.line-textarea {
  margin: 8px 0px 0px 0px;
  border: 1px solid #d0d0d0;
  border-radius: 8px;
  width: 100%;
  padding: 8px;
}
.line-create-message-button {
  border: 0;
  background-color: #3d61d7;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 20px;
  width: 120px;
  height: 40px;
  margin: 0 0 0 16px;
}

.line-create-message-button-container {
  display: flex;
  justify-content: flex-end;
}
.line-message-container {
  display: flex;
}
.line-message-image {
  width: 150px;
}
.line-card-dialog {
  width: 100%;
}
