.selection-modal {
  max-width: 700px;
  .list-group {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    .list-item {
      margin: 4px 8px;
      button {
        padding: 4px 8px;
        background-color: white;
        border: 1px solid rgb(18, 89, 196);
        border-radius: 16px;
        font-size: 16px;
        color: rgb(18, 89, 196);
      }
    }
  }
}
